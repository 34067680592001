import React, { useRef, useEffect } from 'react';
import { useParams } from 'react-router';
import { useState } from 'react';
import axios from 'axios';
import { Constants } from '../../../../constants/Constants';
import Logo from '../../../../assets/images/logo.svg';
import User from '../../../../assets/images/pdf_user_icon.jpg';
import { useTranslation } from 'react-i18next';
import { convertArabicDateToEnglish } from 'views/utilities/helper';


const returnConditionalQuestion = (version) => {

  const commonQuestionsForFirstPerson = [
    {
      displayQuestion: 'Are you allergic to anything?',
      condition: 'Yes',
      nextQuestionCondition: 'Yes'
    },
    {
      displayQuestion: 'Please tell us what allergies you have'
    },
    {
      displayQuestion: 'Are you having chemotherapy?',
      condition: 'Yes'
    },
    {
      displayQuestion: 'Are you having radiotherapy?',
      condition: 'Yes'
    },
    {
      displayQuestion: 'Are you having immunotherapy or are you immunosuppressed?',
      condition: 'Yes'
    },
    {
      displayQuestion: 'Do you think you are at risk of domestic abuse?',
      condition: 'Yes'
    },
    {
      displayQuestion: 'Do you feel safe at home?',
      condition: 'No'
    },
    {
      displayQuestion: 'Do you have any support from the following?',
      condition: ['Social Services', 'Mental Health Services', 'Other'],
    },
    {
      displayQuestion: "Please tell us what services they have support from"
    },
    {
      displayQuestion: 'Please select your accommodation status from the list below',
      condition: ['Homeless and sleeping in a night shelter', 'Homeless and do not have any accommodation']
    },
    {
      displayQuestion: 'Are you deaf or hard of hearing?',
      condition: "Yes"
    },
    {
      displayQuestion: 'Are you a military veteran?',
      condition: "Yes"
    }
   
  ];
  
  const commonQuestionsForChild =  [
    {
      displayQuestion: 'Is the child allergic to anything?',
      condition: 'Yes',
      nextQuestionCondition: 'Yes'
    },
    {
      displayQuestion: 'Please tell us what allergies the child has'
    },
    {
      displayQuestion: 'Are they having chemotherapy?',
      condition: 'Yes'
    },
    {
      displayQuestion: 'Are they having radiotherapy?',
      condition: 'Yes'
    },
    {
      displayQuestion: 'Are they having immunotherapy or are they immunosuppressed?',
      condition: 'Yes'
    },
    {
      displayQuestion: 'Do you think the child is at risk of domestic abuse?',
      condition: 'Yes'
    },
    {
      displayQuestion: 'Does the child feel safe at home?',
      condition: 'No'
    },
    {
      displayQuestion: "Does the child's family have any support from the following?",
      condition: ['Social Services', "Mental Health Services", 'Other'],
      nextQuestionCondition: 'Other'
    },
    {
      displayQuestion: 'Please tell us what services you have support from',
    },
    {
      displayQuestion: 'Please select their accommodation status from the list below',
      condition: ['Homeless and sleeping in a night shelter', 'Homeless and do not have any accommodation']
    },
    {
      displayQuestion: 'Are they deaf or hard of hearing?',
      condition: "Yes"
    },
    {
      displayQuestion: ' Are they a military veteran?',
      condition: "Yes"
    }
  ];
  
  const commonQuestionsForProxy =  [
    {
      displayQuestion: 'Are they allergic to anything?',
      condition: 'Yes',
      nextQuestionCondition: 'Yes'
    },
    {
      displayQuestion: 'Please tell us what allergies they have'
    },
    {
      displayQuestion: 'Are they having chemotherapy?',
      condition: 'Yes'
    },
    {
      displayQuestion: 'Are they having radiotherapy?',
      condition: 'Yes'
    },
    {
      displayQuestion: 'Are they having immunotherapy or are they immunosuppressed?',
      condition: 'Yes'
    },
    {
      displayQuestion: 'Do you think they are at risk of domestic abuse?',
      condition: 'Yes'
    },
    {
      displayQuestion: 'Do they feel safe at home?',
      condition: 'No'
    },
    {
      displayQuestion: 'Do they have any support from the following?',
      condition: ['Social Services', "Mental Health Services", 'Other'],
      nextQuestionCondition: 'Other'
    },
    {
      displayQuestion: 'Please tell us what services you have support from',
    },
    {
      displayQuestion: 'Please select their accommodation status from the list below',
      condition: ['Homeless and sleeping in a night shelter', 'Homeless and do not have any accommodation']
    },
    {
      displayQuestion: 'Are they deaf or hard of hearing?',
      condition: "Yes"
    },
    {
      displayQuestion: ' Are they a military veteran?',
      condition: "Yes"
    }
  ];

  switch (version) {
    case 'firstPerson':
      return commonQuestionsForFirstPerson;
    case 'paediatric':
      return commonQuestionsForChild;
    case 'proxy':
      return commonQuestionsForProxy;
    default:
      return [];
  }
};

export default function ClinicalPathTester({ data }) {
  const params = useParams();
  const [userData, setUserData] = useState();
  const [keySafetyQuestions, setKeySafetyQuestions] = useState([]);
  const [ecdsUkQuestions, setEcdsUkQuestions] = useState([]);
  const [publicHealthQuestions, setPublicHealthQuestions] = useState([]);
  const [matchedCombinationQuestions, setMatchedCombinationQuestions] = useState([]);
  const [color, setColor] = useState('#e4e7ec');
  const [timer, setTimer] = useState('N/A');
  const [type, setType] = useState('N/A');
  const [violence, setViolence] = useState(false);
  const [serviceName, setServiceName] = useState('');
  const [bookedForMe, setBookedForMe] = useState(false);
  const [p, setP] = useState('');
  const [allergies, setAllergies] = useState('');
  const [matchedAllergiesList, setMatchedAllergiesList] = useState([]);
  const [childPresence, setChildPresence] = useState(false);
  const [orderedQuestions, setOrderedQuestions] = useState({ p1: [], p2: [], p3: [], p4: [], p5: [], p0: [] });
  const { t } = useTranslation();

  const headers = {
    'content-type': 'application/json'
  };

  const getTime = (myTime) => {
    const t = new Date(parseInt(myTime));

    const m = t.getMinutes();
    const s = t.getSeconds();

    return `${m}m ${s}s`;
  };

  const matchRecordsWithAllergyAlerts = (records = [], allergyAlerts = []) => {
    allergyAlerts = allergyAlerts.filter(({ isActive, isDeleted }) => isActive && !isDeleted);
    if (!allergyAlerts.length) return;
    const matchedAlerts = allergyAlerts.filter(({ allergyAlert }) =>
      allergyAlert?.every((c) => {
        return records.find((record) => c.question === record.questionDetails.id && record.answers.map((op) => op.id).includes(c.option));
      })
    );
    if (matchedAlerts.length) setMatchedAllergiesList(matchedAlerts.map(({ allergies }) => allergies).flat());
  };

  const matchRecordsWithCombination = (records = [], combination = []) => {
    combination = combination.filter(({ isActive, isDeleted }) => isActive && !isDeleted);
    const matchedCombinations = combination.filter(({ combination }) =>
      combination?.every((c) => {
        return records.find((record) => c.question === record.questionDetails.id && record.answers.map((op) => op.id).includes(c.option));
      })
    );
    const matchedCombinationPValues = matchedCombinations.map(({ overAllCode }) => overAllCode).sort();
    if (matchedCombinationPValues.length)
      setMatchedCombinationQuestions(
        matchedCombinations
          .map(({ combination }) => combination)
          .flat()
          .map((q) => records.find((rec) => q.question === rec.questionDetails.id))
          .map((q) => {
            return {
              question: q.questionDetails.content,
              answer: q.answers.map((ans) => {
                return (
                  <>
                    {ans.content}
                    <br />
                  </>
                );
              })
            };
          })
      );

    return matchedCombinationPValues;
  };

  const makeApiCall = async () => {

    await axios
      .post(`${Constants.baseURL}auth/getClinicalPathUserDataForNonAuth`, { userId: params.id }, { headers })
      .then((e) => {
        let uData = e.data.data;
        setUserData(uData.user);
        setTimer(uData?.timer ? uData?.timer : 'N/A');
        setType(uData?.problem_type ? uData?.problem_type : 'N/A');

        if (uData?.publicHealthQuestions?.length) setPublicHealthQuestions(uData.publicHealthQuestions);

        const updatedEcdsUkQuestions = [];
        const updatedKeySafetyQuestions = [];
        const commonQuestions = returnConditionalQuestion(uData?.version)

        let p5 = [],
          p4 = [],
          p3 = [],
          p2 = [],
          p1 = [],
          p0 = [],
          pathwayPValues = [],
          combinationPValues = [],
          ecdsPValues = [];
        uData?.record?.forEach((q, i, questions) => {
          if (!q?.questionDetails || !q?.answers?.length) return;
          if (q.answers[0].code !== '') {
            pathwayPValues.push(q.answers[0].code);
          }
          const code = q.answers[0].code?.toLowerCase();
          q = {
            question: q.questionDetails.content,
            answer: q.answers.map((ans) => {
              return (
                <>
                  {q.questionDetails.questionType === 'input' ? '*' + ans.content : ans.content}
                  <br />
                </>
              );
            }),
            code: q.answers[0].code,
            nextQuestionNumber: q.nextQuestionNumber,
            condition: q?.answers?.[0].content 
          };
          const nextQuestion = questions.at(i + 1);
          if (nextQuestion && nextQuestion?.questionDetails?.questionType === 'input') questions.at(i + 1).answers[0].code = q.code;

          if (code === 'p5') {
            const isQuestionCommon = commonQuestions.find((question) => question.displayQuestion === q.question);
            const hasCondition = isQuestionCommon?.condition;
            
            if (hasCondition) {              
              const conditionsMet = Array.isArray(hasCondition) ? hasCondition.includes(q.condition) : hasCondition === q.condition;

              if (conditionsMet) {
                updatedKeySafetyQuestions.push(q);
              } else {
                p5.push(q);
              }
            }else if (isQuestionCommon){
                const currentQuestionIndex = commonQuestions.findIndex(question => question.displayQuestion === isQuestionCommon?.displayQuestion)
                const previousQuestion = commonQuestions[currentQuestionIndex-1]
                const userFiledQuestion = questions.find(item => item.questionDetails.content === previousQuestion.displayQuestion);
                
                if (userFiledQuestion.answers[0].content === previousQuestion.nextQuestionCondition) {
                  updatedKeySafetyQuestions.push(q);
                } else {
                  p5.push(q);
                }
            }
          } else if (code === 'p4') p4.push(q);
          else if (code === 'p3') p3.push(q);
          else if (code === 'p2') p2.push(q);
          else if (code === 'p1') p1.push(q);
          else if (code === 'p0') p0.push(q);
        });

        const o_data = { p1, p2, p3, p4, p5, p0 };
        setOrderedQuestions(o_data);
        pathwayPValues = [...new Set(pathwayPValues)].filter((value) => value !== '').sort();

        // Loop through each common question
        uData?.user?.ecdsPathwayData?.record.forEach((record) => {
          const questionText = record.questionDetails.content;
          const answerContent = record.answers[0]?.content;
      
          // Check if the question is in commonQuestions
          const commonQuestion = commonQuestions.find(q => q.displayQuestion === questionText);
      
          if (commonQuestion) {
              if (commonQuestion.condition) {
                  const conditionsMet = Array.isArray(commonQuestion.condition) ? 
                      commonQuestion.condition.includes(answerContent) : 
                      commonQuestion.condition === answerContent;
      
                  if (conditionsMet) {
                      updatedKeySafetyQuestions.push({ question: questionText, answer: answerContent });
                  } else {
                      updatedEcdsUkQuestions.push({ question: questionText, answer: answerContent });
                  }
              } else {
                  const previousQuestion = commonQuestions[commonQuestions.indexOf(commonQuestion) - 1];
                  const previousAnswer = previousQuestion && record.answers[0]?.content;
      
                  if (previousQuestion?.nextQuestionCondition && previousAnswer === previousQuestion.nextQuestionCondition) {
                      updatedKeySafetyQuestions.push({ question: questionText, answer: answerContent });
                  } else {
                      updatedEcdsUkQuestions.push({ question: questionText, answer: answerContent });
                  }
              }
          } else {
              updatedEcdsUkQuestions.push({ question: questionText, answer: answerContent });
          }
      });

      const uniqueEcdsUkQuestions = [...new Set(updatedEcdsUkQuestions.map(q => q.question))].map(question => {
        return updatedEcdsUkQuestions.find(q => q.question === question);
      });

      const uniqueKeySafetyQuestions = [...new Set(updatedKeySafetyQuestions.map(q => q.question))].map(question => {
        return updatedKeySafetyQuestions.find(q => q.question === question);
      });

        setKeySafetyQuestions(uniqueKeySafetyQuestions);
        setEcdsUkQuestions(uniqueEcdsUkQuestions);

        let domVio = uData?.record?.find((e) =>
          e.questionDetails.content.includes('Do you think you are at risk of domestic abuse?' || 'هل تعتقد أنك معرض لخطر العنف المنزلي؟')
        );

        if (domVio && domVio.answers[0].content.toLowerCase() === 'yes') setViolence(true);

        let socSec = uData?.record?.find((e) =>
          e.questionDetails.content.includes('Do you have any support from the following?' || 'هل لديك أي دعم مما يلي؟')
        );
        if (socSec && socSec.answers[0]) {
          if (socSec.answers[0].content.toLowerCase().includes('other')) {
            let serSupport = uData?.record?.find((e) =>
              e.questionDetails.content.includes(
                'Please tell us what services you have support from' || 'يُرجى إخبارنا عن الخدمات التي يتلقى منها الدعم'
              )
            );
            if (serSupport && serSupport.answers[0]) {
              setServiceName(serSupport.answers[0].content);
            } else {
              setServiceName('Not Described');
            }
          } else {
            setServiceName(socSec.answers[0].content);
          }
        }
        //if (socSec && socSec.answer.toLowerCase().includes('social services')) setSecurity(true);

        let userAllergies = uData?.record?.find((e) =>
          e.questionDetails.content
            .toLowerCase()
            .includes('please tell us what allergies you have' || 'من فضلك قل لنا ما هي الحساسية التي لديك')
        );
        if (userAllergies) setAllergies(userAllergies.answers[0].content);

        let _childPresence = uData?.record?.find((e) =>
          e.questionDetails.content.toLowerCase().includes('were there any children present' || 'هل كان هناك أطفال؟')
        );
        if (_childPresence) setChildPresence(true);

        if (uData?.user?.bookingFor === 'I am booking in for myself' || uData?.user?.bookingFor === 'أحجز لنفسي') {
          setBookedForMe(true);
        }

        if (!uData?.disease) return;

        matchRecordsWithAllergyAlerts(uData?.record, uData?.disease?.allergyAlert);
        combinationPValues = matchRecordsWithCombination(uData?.record, uData?.disease?.combination);
        ecdsPValues = matchRecordsWithCombination(uData?.user?.ecdsPathwayData?.record, uData?.disease?.ecdsCombination);
        const largetPValue = [...new Set(pathwayPValues.concat(combinationPValues, ecdsPValues))].sort();
        setP(largetPValue[0]);
        switch (largetPValue[0]) {
          case 'P1':
            setColor(Constants.p1Color);
            break;
          case 'P2':
            setColor(Constants.p2Color);
            break;
          case 'P3':
            setColor(Constants.p3Color);
            break;
          case 'P4':
            setColor(Constants.p4Color);
            break;
          case 'P5':
            setColor(Constants.p5Color);
            break;
        }
      })
      .catch((e) => {
        console.log('Error while api call', e);
      });
  };

  useEffect(() => {
    if (params) {
      makeApiCall();
    }
  }, [params]);

  const getYesNo = (value) => {
    if (value) return `${t('Yes')}`;
    else return `${t('No')}`;
  };

  const ref = useRef();
  
  return (
    <div className="App">
      <div className="element-to-print" ref={ref}>
        <div className="px-5">
          {matchedAllergiesList.length > 0 && (
            <div className="d-flex my-3 gap-2 px-2">
              {matchedAllergiesList.map((a, i) => {
                return (
                  <div
                    key={i}
                    className="rounded py-1"
                    style={{
                      backgroundColor: '#004361',
                      color: '#fff',
                      flex: 1,
                      textAlign: 'center',
                      fontSize: 14,
                      fontWeight: 600
                    }}
                  >
                    {a}
                  </div>
                );
              })}
            </div>
          )}
          <div className="logos">
            <img className="user" src={User} alt="user" />
            <img className="logo" src={Logo} alt="logo" />
          </div>
          <div className="box-1">
            <div className="row">
              <div className="col-9">
                <div className="pds_box">
                  <h3>PDS</h3>
                  <table>
                    <tr>
                      <th className="thead">{t('First_Name')}</th>
                      <td className="tdata">{`${userData?.firstname ? userData?.firstname?.substring(0, 30) : '-'}`}</td>
                    </tr>
                    <tr>
                      <th className="thead">{t('Surname')}</th>
                      <td className="tdata">{`${userData?.lastname ? userData?.lastname?.substring(0, 30) : '-'}`}</td>
                    </tr>
                    <tr>
                      <th className="thead">{t('Date_of_Birth')}</th>
                      <td className="tdata">{userData?.dob ? convertArabicDateToEnglish(userData.dob) : ''}</td>
                    </tr>
                    <tr>
                      <th className="thead">{t('NHS_No')}</th>
                      <td className="tdata">
                        {userData?.nhsNumber && parseInt(userData?.nhsNumber) > 0 ? userData?.nhsNumber : `${t('Not_Found')}`}
                      </td>
                    </tr>
                    <tr>
                      <th className="thead">{t('Gender')} </th>
                      <td className="tdata">{userData?.gender}</td>
                    </tr>
                    {userData?.phone && userData?.phone !== -1 && userData?.phone !== '-1' && (
                      <tr>
                        <th className="thead">{t('Phone_Number')} </th>
                        <td className="tdata">{userData?.phone ? `+${userData?.phone?.toString()?.substring(0, 30)}` : '-'}</td>
                      </tr>
                    )}
                    <tr>
                      <th className="thead">{t('Postal_Code')} </th>
                      <td className="tdata">{userData?.postCode ? userData?.postCode?.substring(0, 30) : '-'}</td>
                    </tr>
                    {userData?.identifyGender && (
                      <tr>
                        <th className="thead">{t('Identity')}</th>
                        <td className="tdata">{userData?.identifyGender}</td>
                      </tr>
                    )}
                    <tr>
                      <th className="thead">{t('Time_Taken')} </th>
                      <td className="tdata">{timer && timer !== 'N/A' ? getTime(timer) : '-'}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="col-3">
                <div className="complaint">
                  <h3>{t('Presenting_Complaint')}</h3>
                  <div className="pain" style={{ height: userData?.phone ? 255 : 230 }}>
                    <h4>{type}</h4>
                    <div className="circle" style={{ backgroundColor: color }}>
                      <h4>{p}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-1 box-2">
            <div className="row">
              <div className="col-md-12">
                <div className="pds_box">
                  <h3>{t('key_safety_information')}</h3>
                  <table>
                    {keySafetyQuestions.map((item, index) => (
                      <tr key={index}>
                        <th className="thead">{item.question}</th>
                        <td className="tdata">{item.answer}</td>
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
          {publicHealthQuestions.length > 0 && (
            <div className="box-1 box-2">
              <div className="row">
                <div className="col-md-12">
                  <div className="pds_box">
                    <h3>Public Health Questions</h3>
                    <table>
                      {publicHealthQuestions.map((q, i) => {
                        return (
                          <tr key={i}>
                            <th className="thead">{q.question}</th>
                            <td className="tdata">{q.option === 'true' ? 'Yes' : q.option === 'false' ? 'No' : ''}</td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          {matchedCombinationQuestions.length > 0 && (
            <div className="box-1 box-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="pds_box">
                    <h3>Increased Priority Scoring with Combined Answers</h3>
                    <table>
                      {matchedCombinationQuestions.map((q, i) => {
                        return (
                          <tr key={i}>
                            <th className={'thead ' + p.toLowerCase()}>{q.question}</th>
                            <td className={'tdata ' + p.toLowerCase()}>{q.answer}</td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="box-1 box-3">
            <div className="row">
              <div className="col-md-12">
                <div className="pds_box">
                  <h3>{t('Clinical_assessment')}</h3>
                  <table>
                    {orderedQuestions.p1.map((e, i) => (
                      <tr key={i}>
                        <th className={`thead p1`}>{e.question}</th>
                        <td className={`tdata p1`}>{e.answer}</td>
                      </tr>
                    ))}
                    {orderedQuestions.p2.map((e, i) => (
                      <tr key={i}>
                        <th className={`thead p2`}>{e.question}</th>
                        <td className={`tdata p2`}>{e.answer}</td>
                      </tr>
                    ))}
                    {orderedQuestions.p3.map((e, i) => (
                      <tr key={i}>
                        <th className={`thead p3`}>{e.question}</th>
                        <td className={`tdata p3`}>{e.answer}</td>
                      </tr>
                    ))}
                    {orderedQuestions.p4.map((e, i) => (
                      <tr key={i}>
                        <th className={`thead p4`}>{e.question}</th>
                        <td className={`tdata p4`}>{e.answer}</td>
                      </tr>
                    ))}
                    {orderedQuestions.p5.map((e, i) => (
                      <tr key={i}>
                        <th className={`thead p5`}>{e.question}</th>
                        <td className={`tdata p5`}>{e.answer}</td>
                      </tr>
                    ))}
                    {orderedQuestions.p0.map((e, i) => (
                      <tr key={i}>
                        <th className={`thead p0`}>{e.question}</th>
                        <td className={`tdata p0`}>{e.answer}</td>
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="box-1 box-2">
            <div className="row">
              <div className="col-md-12">
                <div className="pds_box">
                  <h3>ECDS</h3>
                  <table>
                    {ecdsUkQuestions?.length ? (
                      ecdsUkQuestions?.map((q) => {
                        return (
                          <tr>
                            <th className="thead">{q.question}</th>
                            <td className="tdata">{q.answer}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <>
                        <tr>
                          <th className="thead">{userData?.ecds?.Ethnic_Category_ques || t('Please_tell_us_your_ethnicity')}</th>
                          <td className="tdata">{userData?.ecds?.Ethnic_Category?.answer}</td>
                        </tr>
                        <tr>
                          <th className="thead">{userData?.ecds?.Spoken_Language_ques || t('What_is_your_native_spoken_language')}</th>
                          <td className="tdata">{userData?.ecds?.Spoken_Language?.answer}</td>
                        </tr>
                        <tr>
                          <th className="thead">
                            {userData?.ecds?.Interpreter_Language_Status_ques ||
                              t('Which_interpreter_language_would_you_need_to_help_you_today')}
                          </th>
                          <td className="tdata">
                            {userData?.ecds?.Interpreter_Language?.answer ? userData?.ecds?.Interpreter_Language?.answer : `${t('None')}`}
                          </td>
                        </tr>
                        <tr>
                          <th className="thead">{userData?.ecds?.Accommodation_Status_ques || t('What_is_your_accommodation_status')}</th>
                          <td className="tdata">{userData?.ecds?.Accommodation_Status?.answer}</td>
                        </tr>
                        <tr>
                          <th className="thead">{userData?.ecds?.Arrival_Mode_ques || t('How_did_you_travel_here_today')}</th>
                          <td className="tdata">{userData?.ecds?.Arrival_Mode?.answer}</td>
                        </tr>
                        <tr>
                          <th className="thead">
                            {userData?.ecds?.Addendance_Sources_Status_ques || t('Has_anyone_told_you_to_come_here_today')}
                          </th>
                          <td className="tdata">{userData?.ecds?.Addendance_Sources_Status ? `${t('Yes')}` : `${t('No')}`}</td>
                        </tr>
                        {userData?.ecds?.Addendance_Sources_Status && (
                          <tr>
                            <th className="thead">{userData?.ecds?.Addendance_Sources_ques || t('Who_told_you_to_come_here')}</th>
                            <td className="tdata">{userData?.ecds?.Addendance_Sources?.answer}</td>
                          </tr>
                        )}
                        <tr>
                          <th className="thead">{userData?.ecds?.Temperature_ques || t('Temperature')}</th>
                          <td className="tdata">{getYesNo(userData?.ecds?.Temperature)}</td>
                        </tr>
                        <tr>
                          <th className="thead">
                            {userData?.ecds?.Attendance_Activity_ques ||
                              t('Have_you_been_to_this_Emergency_Department_in_the_last_7_days_for_the_same_problem_you_have_today')}
                          </th>
                          <td className="tdata">{userData?.ecds?.Attendance_Activity?.answer}</td>
                        </tr>
                        <tr>
                          <th className="thead">
                            {userData?.ecds?.Support_Communicating_Status_ques || `Do you identify as “deaf” or “hard of hearing”?`}
                          </th>
                          <td className="tdata">{getYesNo(userData?.ecds?.Support_Communicating_Status)}</td>
                        </tr>
                        {userData?.ecds?.Support_Communicating_Status === true && (
                          <tr>
                            <th className="thead">
                              {userData?.ecds?.Support_Communicating_ques || `Do you need support with communicating from the following?`}
                            </th>
                            <td className="tdata">{userData?.ecds?.Support_Communicating?.answer}</td>
                          </tr>
                        )}
                        <tr>
                          {/* <th className="thead">Injury</th> */}
                          <th className="thead">{userData?.ecds?.Injury_ques || t('Injury')}</th>
                          <td className="tdata">{getYesNo(userData?.ecds?.Injury)}</td>
                        </tr>

                        <tr>
                          <th className="thead">
                            {userData?.ecds?.Outside_UK_ques || `Have you lived outside the UK at all in the past 12 months?`}
                          </th>
                          <td className="tdata">{getYesNo(userData?.ecds?.Outside_UK)}</td>
                        </tr>

                        {userData?.ecds?.hasOwnProperty('Military') && (
                          <tr>
                            {/* {bookedForMe ? (
                          <th className="thead">Are you a military veteran?</th>
                        ) : (
                          <th className="thead">Are they a military veteran?</th>
                        )} */}
                            <th className="thead">{userData?.ecds?.Military_ques || `Are they a military veteran?`}</th>
                            <td className="tdata">{getYesNo(userData?.ecds?.Military)}</td>
                          </tr>
                        )}

                        {userData?.ecds?.Injury && (
                          <>
                            <tr>
                              <th className="thead">{userData?.ecds?.Place_Of_Injury_ques || t('Where_did_the_injury_take_place')}</th>
                              <td className="tdata">{userData?.ecds?.Place_Of_Injury?.answer}</td>
                            </tr>
                            <tr>
                              <th className="thead">{userData?.ecds?.Injury_time_ques || t('Time_of_Injury')}</th>
                              <td className="tdata">{userData?.ecds?.Injury_time}</td>
                            </tr>
                            <tr>
                              <th className="thead">{userData?.ecds?.Injury_date_ques || t('Date_of_Injury')}</th>
                              <td className="tdata">{userData?.ecds?.Injury_date}</td>
                            </tr>
                            <tr>
                              <th className="thead">{userData?.ecds?.Injury_Intent_ques || t('How_did_the_injury_happen')}</th>
                              <td className="tdata">{userData?.ecds?.Injury_Intent?.answer}</td>
                            </tr>
                            <tr>
                              <th className="thead">
                                {userData?.ecds?.Injury_Acrtivity_Status_ques || t('What_were_you_doing_when_you_got_injured')}
                              </th>
                              <td className="tdata">{userData?.ecds?.Injury_Acrtivity_Status?.answer}</td>
                            </tr>
                            <tr>
                              <th className="thead">
                                {userData?.ecds?.Injury_Activity_Type_ques || t('What_were_you_doing_to_get_injured')}
                              </th>
                              <td className="tdata">{userData?.ecds?.Injury_Activity_Type?.answer}</td>
                            </tr>
                          </>
                        )}
                      </>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="box-5">
            <h3>{t('Guide_to_Answer_Types')}</h3>
            <div className="guide_box">
              <p className="p1">{t('P1_Immediate')}</p>
              <p className="p2">{t('P2_Very_urgent')}</p>
              <p className="p3">{t('P3_Urgent')}</p>
              <p className="p4">{t('P4_Standard')}</p>
              <p className="p5">{t('P5_Non_urgent')}</p>
            </div>
          </div>
          <p className="mb-0 px-3 mt-3" style={{ color: 'white', fontSize: 'inherit' }}>
            *Disclaimer: We recommend that the free text answers be verified with the patient, as they have either been recorded verbatim or
            translated using Google Translate.
          </p>
        </div>
      </div>
    </div>
  );
}
